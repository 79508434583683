import { ThunkActionType } from 'store/store';
import { getUserData, redirectToLogin } from 'store/auth/authThunk';
import { resetActionsState } from 'store/actions/actionsReducer';
import { resetFlowsState } from 'store/flows/flowsReducer';
import { resetObjectTypesState } from 'store/objectTypes/objectTypesReducer';
import { resetPlugInsState } from 'store/plugin/pluginReducer';
import { resetTransformersState } from 'store/transformer/transformerReducer';
import { resetTriggersState } from 'store/triggers/triggersReducer';
import { resetViewsState } from 'store/views/viewsReducer';
import {
  resetAppHeaderState, setApiError, setIsAppLoader,
} from './appReducer';

export const handleApiError = (error: any, codeToIgnore?: number): ThunkActionType => {
  return (dispatch) => {
    if (error?.response && error.response.status === codeToIgnore) {
      return;
    }

    if (error?.response) {
      const data = error.response.data || error?.response;

      if (data.message === 'TOKEN_IS_INACTIVE') {
        redirectToLogin();
      }

      dispatch(setApiError({
        error: data.error || data.message,
        errorTarget: data.errorTarget,
      }));
    }
  };
};

export const resetAppHeader = (): ThunkActionType => async (dispatch) => {
  dispatch(resetAppHeaderState());
};

export const initApp = (): ThunkActionType => {
  return async (dispatch) => {
    try {
      dispatch(setIsAppLoader(true));

      dispatch(getUserData());
    } catch (err) {
      dispatch(handleApiError(err));
    } finally {
      dispatch(setIsAppLoader(false));
    }
  };
};

export const resetAppState = (): ThunkActionType => async (dispatch) => {
  dispatch(resetAppHeaderState());
  dispatch(resetFlowsState());
  dispatch(resetObjectTypesState());
  dispatch(resetPlugInsState());
  dispatch(resetTransformersState());
  dispatch(resetTriggersState());
  dispatch(resetViewsState());
  dispatch(resetActionsState());
};
